import Api from "@/services/Api";

export default {

  addDevice(params) {
    return Api().post("addDevice", params);
  },

  getDevice(deviceId) {
    return Api().get("getDevice", {
      params: {
        id: deviceId,
      },
    });
  },

  // Returns an array of all device objects for a given group. Takes an integer as argument.
  getGroupDevices(groupId) {
    return Api().get("getGroupDevices", {
      params: { groupId },
    });
  },

  // Method to get all devices existent on the db. Reserved for Master users.
  getAllDevices() {
    return Api().get("getAllDevices");
  },

  // Method to update a certain device's read information (installation local, name).
  updateDeviceInfo(params) {
    return Api().post("updateDeviceInfo", params);
  },

  // Method to update a device's associative information (refGroup, refPackage, serialNumber). Reserved for Master users.
  updateDevice(params) {
    return Api().post("updateDevice", params);
  },

  setDeviceOffsets(params) {
    return Api().post("setDeviceOffsets", params);
  },
  
  getDeviceOffsets(deviceId) {
    return Api().get("getDeviceOffsets", {
      params: {
        deviceId,
      },
    });
  },

  setDeviceRssi(params) {
    return Api().post("setDeviceRssi", params);
  },
  
  getDeviceRssi(deviceId) {
    return Api().get("getDeviceRssi", {
      params: {
        deviceId,
      },
    });
  },

  // Method to set the firmware offsets updated status to either true or false of a certain device. Takes a device ID and a boolean as arguments.
  setOffsetsUpdatedStatus(params) {
    return Api().post("setOffsetsUpdatedStatus", params)
  },

  setRssiUpdatedStatus(params) {
    return Api().post("setRssiUpdatedStatus", params)
  },

  delete(id) {
    return Api().delete(`/device/${id}`);
  },
};
