import Api from "@/services/Api";

export default {
  
  getGroupServicesInfo(group) {
    return Api().get("getGroupServicesInfo", {
      params: { group },
    });
  },

  updateGroupInfo(info) {
    return Api().post("updateGroupInfo", info);
  },

  getGroupUsers(refGroup) {
    return Api().get("getGroupUsers", {
      params: { refGroup },
    });
  },
  
  // Returns an array of objects containing all info from the app groups
  getAllGroups() {
    return Api().get("getAllGroups");
  },

  createGroup(info) {
    return Api().post("createGroup", info);
  },

  delete(id) {
    return Api().delete(`/group/${id}`);
    // return Api().delete('/entry/' + id);
  },
};
