<template>
  <v-container class="mother">
    <v-row class="topObj mt-0">
      <v-col
        class="mainButtons pl-4 pr-4 pt-0"
        xs="3"
        sm="3"
        md="3"
        lg="3"
        xl="3"
      >
        <v-card
          elevation="2"
          color="grey lighten-3"
          style="height: 46vh; width: 18vw"
          class="pt-2"
        >
          <v-row class="mt-2 justify-center">
            <v-select
              v-if="changeViewButton"
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="views"
              v-model="selectedView"
              @change="changeView(selectedView)"
              :label="$t('button.chooseView')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="mt-2 justify-center">
            <v-select
              v-if="!changeViewButton"
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="views"
              v-model="selectedView"
              @change="changeView(selectedView)"
              :label="$t('button.chooseView')"
              dense
              disabled
              outlined
            ></v-select>
          </v-row>

          <v-row class="mt-2 justify-center" style="width: 18.8vw">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="appGroupsNames"
              v-model="selectedGroupName"
              @change="getGroupObj()"
              :label="$t('button.selectGroup')"
              dense
              outlined
              hideDetails
            ></v-select>
          </v-row>

          <v-row class="mt-2 justify-center">
            <div class="text-center">
              <v-dialog v-model="dialog" width="500" hide-overlay>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="d-flex mt-6"
                    elevation="2"
                    v-bind="attrs"
                    v-on="on"
                    @click="initSession"
                  >
                    {{ $t("button.getData") }}
                  </v-btn>
                </template>

                <v-card v-if="alertPopup">
                  <v-card-title class="text-h5 blue darken-1">
                    {{ alertTitle }}
                  </v-card-title>

                  <v-card-text>
                    {{ alertMessage }}
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                      OK
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-row>

          <v-row class="mt-2 justify-center" v-if="!view">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-6"
              v-model="selectedDevice"
              :items="devicesToSelect"
              @change="updateSelectedDevice(selectedDevice)"
              :label="$t('button.chooseDevice')"
              dense
              outlined
              hideDetails
            ></v-select>
          </v-row>

          <v-row class="mt-2 justify-center" v-if="view">
            <v-select
              background-color="#FAFAFA"
              v-if="view"
              class="ml-10 mr-10 mt-6"
              :items="floorsToChoose"
              v-model="floor"
              @change="blueprintByFloor()"
              :label="$t('button.floorView')"
              dense
              outlined
              hideDetails
            ></v-select>
          </v-row>

          <v-row class="justify-center" v-if="!view">
            <v-spacer v-if="$i18n.locale == 'en'" class="mt-3"
              ><div style="font-size: 1.1vw">Last Reading:</div></v-spacer
            >
            <v-spacer v-if="$i18n.locale == 'pt'" class="mt-6"
              ><div style="font-size: 1.1vw">Última Leitura:</div></v-spacer
            >
          </v-row>
          <v-row class="justify-center" v-if="!view">
            <v-card
              outlined
              color="#FAFAFA"
              style="
                height: 8vh;
                width: 10vw;
                border-width: 1px;
                border-color: #757575;
                color: #424242;
              "
            >
              <div class="mt-1" style="font-size: 1.1vw">{{ time }}</div>
            </v-card>
          </v-row>
        </v-card>
      </v-col>

      <!-- BLUEPRINT -->
      <v-col
        v-if="view"
        class="blueprint pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card elevation="2" color="grey lighten-3" style="height: 46vh">
          <canva-obj
            obj
            ref="canvaObj"
            :path="path"
            :blueprintId="blueprintId"
          ></canva-obj>
        </v-card>
      </v-col>

      <!-- DASHBOARD -->
      <v-col
        v-if="!view"
        class="dashboard pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card
          md="9"
          lg="9"
          xl="9"
          elevation="2"
          color="grey lighten-3"
          style="height: 46vh"
          class="pt-0"
        >
          <v-row style="height: 14vh" class="ma-0">
            <v-col class="pr-1" md="3" lg="3" xl="3">
              <dashboard-card
                v-bind:value="temperature"
                parameter="Temperature"
                unit="ºC"
                path="temp.png"
                :event="showEvents.temperature"
              />
            </v-col>
            <v-col class="pl-1 pr-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="co2"
                parameter="Carbon Dioxide"
                unit="ppm"
                path="co2.png"
                :event="showEvents.co2"
              />
            </v-col>
            <v-col class="pr-1 pl-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="noise"
                parameter="Noise"
                unit="dB"
                path="noise.png"
                :event="showEvents.noise"
              />
            </v-col>
            <v-col class="pl-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="humidity"
                parameter="Humidity"
                unit="%"
                path="humi.png"
                :event="showEvents.humidity"
              />
            </v-col>
          </v-row>
          <v-row style="height: 14vh" class="ma-0">
            <v-col class="mt-1 pr-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="lux"
                parameter="Light Intensity"
                unit="lux"
                path="intluz.png"
                :event="showEvents.light"
              />
            </v-col>
            <v-col class="mt-1 pl-1 pr-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="tvoc"
                parameter="Air Quality"
                unit="ppb"
                path="qualar.png"
                :event="showEvents.air"
              />
            </v-col>
            <v-col class="mt-1 pl-1 pr-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="pressure"
                parameter="Pressure"
                unit="hPa"
                path="preatm.png"
                :event="showEvents.pressure"
              />
            </v-col>
            <v-col class="mt-1 pl-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="wifi"
                parameter="Wifi Signal"
                unit="dBm"
                path="wifi.png"
                :event="showEvents.wifi"
              />
            </v-col>
          </v-row>

          <v-row style="height: 14vh" class="ma-0">
            <v-col class="mt-2 pr-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="r"
                :value1="g"
                :value2="b"
                parameter="Light Colorimetry"
                unit="r"
                unit1="g"
                unit2="b"
                path="colo.png"
                :event="{}"
              />
            </v-col>
            <v-col class="mt-2 pr-1 pl-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="lightTemp"
                parameter="Light Temperature"
                unit="ºK"
                path="temluz.png"
                :event="showEvents.lightTemp"
              />
            </v-col>
            <v-col class="mt-2 pr-1 pl-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="devicesCount"
                parameter="Number of Devices"
                unit="un"
                path="numpes.png"
                :event="showEvents.deviceCount"
              />
            </v-col>
            <v-col class="mt-2 pl-1" md="3" lg="3" xl="3">
              <dashboard-card
                :value="devicesFlow"
                parameter="Devices Flow"
                unit="un"
                path="flupes.png"
                :event="showEvents.peopleFlow"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="botObj mt-8">
      <!-- BAR GRAPH-->
      <v-col
        v-if="view"
        class="barGraphButtons pl-4 pr-4 pt-0"
        md="3"
        lg="3"
        xl="3"
      >
        <v-card
          elevation="2"
          color="grey lighten-3"
          style="height: 46vh; width: 18vw"
          class="mt-3"
        >
          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-4"
              :items="deviceGroups"
              v-model="deviceGroup"
              @change="selectedDeviceGroup()"
              :label="$t('button.chooseGroupOfDevices')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="dataToSelect"
              v-model="dataType"
              @change="updateBarGraph(dataType)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="view"
        class="barGraph pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card elevation="2" color="grey lighten-3" style="height: 46vh">
          <v-col>
            <bar-graph
              :devices="barChartDevices"
              :seriesData="barChartSeriesData"
              :date="barChartDates"
              :dataType="this.dataType + ' by Group of Devices'"
              :measure="this.measure"
              :rotation="this.rotate"
            />
          </v-col>
        </v-card>
      </v-col>

      <!-- LINE GRAPH-->
      <v-col
        v-if="!view"
        class="lineGraphButtons pl-4 pr-4 pt-0"
        md="3"
        lg="3"
        xl="3"
      >
        <v-card
          elevation="2"
          color="grey lighten-3"
          class="mt-3"
          style="height: 46vh; width: 18vw"
        >
          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10 mt-4"
              :items="dataToSelect"
              v-model="dataType"
              @change="updateLineGraph(dataType, selectedDevice)"
              :label="$t('button.chooseDataType')"
              dense
              outlined
            ></v-select>
          </v-row>

          <v-row class="justify-center">
            <v-select
              background-color="#FAFAFA"
              class="ml-10 mr-10"
              :items="periodsToSelect"
              v-model="timePeriod"
              @change="updateTimePeriod(timePeriod)"
              :label="$t('button.chooseTimePeriod')"
              dense
              outlined
            ></v-select>
          </v-row>

          <!-- DATE PICKER -->

          <v-row align="center" justify="center">
            <v-card outlined style="border-width: 1px; border-color: #bdbdbd">
              <v-col cols="12" sm="11" md="11" xl="11" class="pa-0">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRange"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRange"
                      label="Date Range"
                      persistent-hint
                      prepend-icon="date_range"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateRange" range no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menu.save(dateRange);
                        passDateRangeToLineGraph();
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-card>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        v-if="!view"
        class="lineGraph pa-0 pr-4"
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="9"
      >
        <v-card elevation="2" color="grey lighten-3" style="height: 46vh">
          <v-col v-if="$i18n.locale == 'en'">
            <line-graph
              :dateList="dates"
              :valueList="series"
              :dataType="this.dataType + ' Evolution Over Time'"
              :measure="this.measure"
            />
          </v-col>
          <v-col v-if="$i18n.locale == 'pt'">
            <line-graph
              :dateList="dates"
              :valueList="series"
              :dataType="this.dataType + ' ao Longo do Tempo'"
              :measure="this.measure"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GroupService from "@/services/GroupService";
import DeviceService from "@/services/DeviceService";
import DataService from "@/services/DataService";
import DashboardCard from "@/components/DashboardCard";
import EventService from "@/services/EventService";
import LineGraph from "@/components/LineGraph";
import HelperMethods from "@/utilities/HelperMethods";
import CanvaObj from "@/components/CanvaObj";
import BarGraph from "@/components/BarGraph";
import BlueprintService from "@/services/BlueprintService";
import DeviceGroupService from "@/services/DeviceGroupService";
import moment from "moment";
import * as echarts from "echarts";

export default {
  components: {
    DashboardCard,
    LineGraph,
    CanvaObj,
    BarGraph,
  },

  data() {
    return {
      // Global Variables
      views: ["Global", "By SensorBlock"],
      selectedView: null,
      appGroupsObj: [],
      appGroupsNames: [],
      selectedGroupObj: {},
      selectedGroupName: null,
      devicesToSelect: [],
      selectedDevice: null,
      groupDevices: [],
      device: [],
      payload: [],
      view: false,
      deviceIsOnline: null,
      dialog: false,
      alertPopup: true,
      alertTitle: "Alert!",
      alertMessage: "Choose a group first, then press 'GET DATA' button.",

      // Event Variables
      events: {},
      showEventsBlueprint: {
        temperature: {
          flag: false,
          severity: null,
          message: "",
        },
        co2: {
          flag: false,
          severity: null,
          message: "",
        },
        noise: {
          flag: false,
          severity: null,
          message: "",
        },
        humidity: {
          flag: false,
          severity: null,
          message: "",
        },
        light: {
          flag: false,
          severity: null,
          message: "",
        },
        air: {
          flag: false,
          severity: null,
          message: "",
        },
        pressure: {
          flag: false,
          severity: null,
          message: "",
        },
        wifi: {
          flag: false,
          severity: null,
          message: "",
        },
        deviceCount: {
          flag: false,
          severity: null,
          message: "",
        },
        lightTemp: {
          flag: false,
          severity: null,
          message: "",
        },
        peopleFlow: {
          flag: false,
          severity: null,
          message: "",
        },
      },
      showEvents: {
        temperature: {
          flag: false,
          severity: null,
          message: "",
        },
        co2: {
          flag: false,
          severity: null,
          message: "",
        },
        noise: {
          flag: false,
          severity: null,
          message: "",
        },
        humidity: {
          flag: false,
          severity: null,
          message: "",
        },
        light: {
          flag: false,
          severity: null,
          message: "",
        },
        air: {
          flag: false,
          severity: null,
          message: "",
        },
        pressure: {
          flag: false,
          severity: null,
          message: "",
        },
        wifi: {
          flag: false,
          severity: null,
          message: "",
        },
        deviceCount: {
          flag: false,
          severity: null,
          message: "",
        },
        lightTemp: {
          flag: false,
          severity: null,
          message: "",
        },
        peopleFlow: {
          flag: false,
          severity: null,
          message: "",
        },
      },

      // Blueprint Variables
      floorsToChoose: [],
      floor: null,
      groupBlueprints: [],
      blueprintId: null,
      path: "",

      // LineGraph Variables
      series: [],
      dates: [],
      measure: "",
      dataType: null,
      dateFrom: "",
      dateTo: "",

      // Bar Graph Variables
      barChartPayload: [],
      barChartDates: [],
      barChartSeriesData: [],
      barChartDevices: [],
      groupDeviceGroups: [],
      deviceGroups: [],
      deviceGroup: "",
      rotate: 0,

      // Button Variables
      timePeriod: null,
      dataToSelect: [
        "Temperature",
        "Carbon Dioxide",
        "Noise",
        "Humidity",
        "Light Intensity",
        "Air Quality",
        "Pressure",
        "Wifi Signal",
        "Light Temperature",
        "Number of Devices",
        "People Flow",
      ],
      periodsToSelect: ["Daily", "Weekly", "Monthly"],

      // Calendar Variables
      dateRange: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      menu: false,

      barChart: true,
      lineChart: false,
      changeViewButton: false,
    };
  },

  methods: {
    async initSession() {
      let self = this;
      this.changeViewButton = true;

      // Method called to populate the devicesToSelect array so we have the group devices on the Choose Device v-select
      await this.getGroupDevices();

      // Getting group services && extras to determine what subscription and features the group has subscribed
      try {
        let response = await GroupService.getGroupServicesInfo(
          this.selectedGroupObj
        );

        let services = [];
        for (let i = 0; i < response.data.package.length; i++) {
          services.push(response.data.package[i].refService);
        }
        for (let i = 0; i < response.data.extras.length; i++) {
          services.push(response.data.extras[i].refService);
        }
        services.sort((a, b) => a - b);

        this.groupServices = HelperMethods.buildServicesObject(services);
      } catch (err) {
        console.log("getGroupServicesInfo at init", err);
      }

      // If the selected group has advPortal feature then we unblock the changeView button
      // if(this.groupServices.advPortal) {
      // this.changeViewButton = true;
      // } else this.changeViewButton = false;

      // If the group has no device configured yet we throw an alert and stop the execution
      if (this.groupDevices.length === 0) {
        return;
      }

      await this.getDefaultDashboardPayload();

      await this.updateLineGraph(this.dataType, this.selectedDevice);

      await this.getGroupBlueprints();

      // In case the group has no blueprint(s) added we do not consider the below block
      if (this.groupBlueprints != 0) {
        // Default blueprint to display
        this.path = this.groupBlueprints[0].path;
        this.blueprintId = Number(this.groupBlueprints[0].id);
        this.floorsToChoose = [];

        this.groupBlueprints.forEach(function (value) {
          self.floorsToChoose.push(value.name)
        })

        this.floor = this.floorsToChoose[0];
      } else {
        console.log("group has no blueprints");
      }

      // Get all deviceGroups from the group to populate the choose device group combobox button of the bar chart
      let groupId = this.selectedGroupObj.id;
      await this.getGroupDeviceGroups(groupId);

      await this.initBarChart();
    },

    getGroupObj() {
      let self = this;

      this.alertPopup = false;

      this.appGroupsObj.forEach(function (value) {
        if (value.name === self.selectedGroupName) {
          self.selectedGroupObj = value;
        }
      });
    },

    async getGroupDevices() {
      let self = this;
      this.devicesToSelect = [];
      let groupId = this.selectedGroupObj.id;

      try {
        let response = await DeviceService.getGroupDevices(groupId);
        this.groupDevices = response.data;
      } catch (err) {
        console.log(err);
      }
      if (this.groupDevices.length === 0) {
        this.alertPopup = true;
        this.alertTitle = "Alert!";
        this.alertMessage = "The selected Group has no devices assigned.";
      } else this.dialog = false;

      this.groupDevices.forEach(function (value) {
        self.devicesToSelect.push(value.name);
      });

      this.selectedDevice = this.devicesToSelect[0];
    },

    async getDefaultDashboardPayload() {
      let defaultDeviceId = this.groupDevices[0].id;

      try {
        let response = await DataService.getCurrentData(defaultDeviceId);
        this.payload = response.data;
      } catch (err) {
        console.log(err);
      }
      let payloadId = this.payload.data.id;
      await this.getDashboardEvents(payloadId, defaultDeviceId);
    },

    async updateSelectedDevice(selectedDevice) {
      this.device = [];

      for (let i = 0; i < this.groupDevices.length; i++) {
        if (selectedDevice === this.groupDevices[i].name) {
          this.device.push(this.groupDevices[i]);
        }
      }
      let deviceId = this.device[0].id;
      await this.getDashboardData(deviceId);

      let payloadId = this.payload.data.id;
      await this.getDashboardEvents(payloadId, deviceId);

      await this.updateLineGraph(this.dataType, this.selectedDevice);
      // this.updateDeviceStatus();
    },

    async getDashboardData(deviceId) {
      try {
        await DataService.getCurrentData(deviceId).then((data) => {
          this.payload = data?.data;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async getDashboardEvents(dataId, deviceId) {
      let self = this;
      try {
        await EventService.getCurrentEvents(dataId, deviceId).then((data) => {
          this.events = data.data;
        });
      } catch (err) {
        this.error = "An error occurred while loading data";
        console.log(err);
      }
      // resetting showEvents variable to its blueprint
      this.showEvents = { ...this.showEventsBlueprint };

      this.events.forEach(function (value) {
        switch (value.dataType) {
          case 0:
            self.showEvents.temperature = {};
            self.showEvents.temperature.flag = true;
            self.showEvents.temperature.severity = value.eventType;
            self.showEvents.temperature.message = value.message;
            break;
          case 1:
            self.showEvents.co2 = {};
            self.showEvents.co2.flag = true;
            self.showEvents.co2.severity = value.eventType;
            self.showEvents.co2.message = value.message;
            break;
          case 2:
            self.showEvents.noise = {};
            self.showEvents.noise.flag = true;
            self.showEvents.noise.severity = value.eventType;
            self.showEvents.noise.message = value.message;
            break;
          case 3:
            self.showEvents.humidity = {};
            self.showEvents.humidity.flag = true;
            self.showEvents.humidity.severity = value.eventType;
            self.showEvents.humidity.message = value.message;
            break;
          case 4:
            self.showEvents.light = {};
            self.showEvents.light.flag = true;
            self.showEvents.light.severity = value.eventType;
            self.showEvents.light.message = value.message;
            break;
          case 5:
            self.showEvents.air = {};
            self.showEvents.air.flag = true;
            self.showEvents.air.severity = value.eventType;
            self.showEvents.air.message = value.message;
            break;
          case 6:
            self.showEvents.pressure = {};
            self.showEvents.pressure.flag = true;
            self.showEvents.pressure.severity = value.eventType;
            self.showEvents.pressure.message = value.message;
            break;
          case 7:
            self.showEvents.wifi = {};
            self.showEvents.wifi.flag = true;
            self.showEvents.wifi.severity = value.eventType;
            self.showEvents.wifi.message = value.message;
            break;
          case 8:
            self.showEvents.deviceCount = {};
            self.showEvents.deviceCount.flag = true;
            self.showEvents.deviceCount.severity = value.eventType;
            self.showEvents.deviceCount.message = value.message;
            break;
          case 9:
            self.showEvents.lightTemp = {};
            self.showEvents.lightTemp.flag = true;
            self.showEvents.lightTemp.severity = value.eventType;
            self.showEvents.lightTemp.message = value.message;
            break;
          case 10:
            self.showEvents.peopleFlow = {};
            self.showEvents.peopleFlow.flag = true;
            self.showEvents.peopleFlow.severity = value.eventType;
            self.showEvents.peopleFlow.message = value.message;
            break;
        }
      });
    },

    updateLineGraph(dataType, selectedDevice) {
      this.measure = HelperMethods.defineDataMeasure(dataType);
      let dataOfType = dataType;
      this.device = [];

      for (let i = 0; i < this.groupDevices.length; i++) {
        if (selectedDevice === this.groupDevices[i].name) {
          this.device.push(this.groupDevices[i]);
        }
      }
      let deviceId = this.device[0].id;

      DataService.getDataOfType(
        deviceId,
        this.dateFrom,
        this.dateTo,
        dataOfType
      )
        .then((data) => {
          this.series = [];
          this.dates = [];

          let payload = data.data;
          let sampleRate = this.$store.state.userServices;

          //The block below is responsible to place null values during the periods of time the device was down
          //The null values will be represented on the graph as empty space
          var sr = null;
          if (sampleRate.sr5 === true) {
            sr = 310;
          } else if (sampleRate.sr10 === true) {
            sr = 610;
          } else if (sampleRate.sr20 === true) {
            sr = 1210;
          }

          for (let i = 0; i < payload.length - 1; i++) {
            let startTime = moment(payload[i].createdAt);
            let endTime = moment(payload[i + 1].createdAt);
            if (endTime.diff(startTime, "seconds") >= sr) {
              var newDate = moment(payload[i].createdAt).add(5, "minutes");
              payload.splice(i + 1, 0, { temp: null, createdAt: newDate });
            }
          }

          //Next block formatts the timestamp to a more easy to interpret format
          payload.forEach(function (value) {
            value.createdAt = HelperMethods.formatTimestamp1(
              value["createdAt"]
            );
            value.createdAt = HelperMethods.formatTimestamp2(
              value["createdAt"]
            );
          });

          //Following code isolates the date values on the "dates" array and the data on the "series" array
          //As the graph needs to be fed with individual arrays for each axis
          this.dates = payload.map(function (item) {
            return item.createdAt;
          });

          let temp = [];
          for (let row of payload) {
            switch (dataOfType) {
              // ENGLISH
              case "Temperature":
                temp.push(row.temp);
                break;
              case "Carbon Dioxide":
                temp.push(row.eco2);
                break;
              case "Noise":
                temp.push(row.dbavg);
                break;
              case "Humidity":
                temp.push(row.humidity);
                break;
              case "Light Intensity":
                temp.push(row.lux);
                break;
              case "Air Quality":
                temp.push(row.tvoc);
                break;
              case "Pressure":
                temp.push(row.pressure);
                break;
              case "Wifi Signal":
                temp.push(row.rssi);
                break;
              case "Light Temperature":
                temp.push(row.light_temp);
                break;
              case "Number of Devices":
                temp.push(row.devicesCount);
                break;
              case "People Flow":
                temp.push(row.devicesFlow);
                break;
              default:
                break;
            }
          }
          this.series.push({
            type: "line",
            lineStyle: {
              color: "#5470C6",
              width: 4,
            },
            showSymbol: false,
            data: temp,
            connectNulls: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateTimePeriod(timePeriod) {
      this.dateTo = moment().add(1, "days").format("YYYY-MM-DD");
      switch (timePeriod) {
        case "Daily":
          this.dateFrom = moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Weekly":
          this.dateFrom = moment()
            .subtract(1, "weeks")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        case "Monthly":
          this.dateFrom = moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD h:mm:ss a");
          break;
        default:
          break;
      }
      this.updateLineGraph(this.dataType, this.selectedDevice);
    },

    passDateRangeToLineGraph() {
      if (this.dateRange.length === 1) {
        this.dateFrom = new Date(this.dateRange[0]);
        this.dateTo = new Date(Date.now());
        this.updateLineGraph(this.dataType, this.selectedDevice);
        return;
      }

      let date1 = new Date(this.dateRange[0]);
      let date2 = new Date(this.dateRange[1]);

      if (date1 > date2) {
        this.dateFrom = date2;
        this.dateTo = date1;
        this.updateLineGraph(this.dataType, this.selectedDevice);
        return;
      } else if (date2 > date1) {
        this.dateFrom = date1;
        this.dateTo = date2;
        this.updateLineGraph(this.dataType, this.selectedDevice);
        return;
      } else {
        this.dateFrom = date1;
        this.dateTo = new Date(Date.now());
        this.updateLineGraph(this.dataType, this.selectedDevice);
        return;
      }
    },

    async getGroupBlueprints() {
      let groupId = this.selectedGroupObj.id;

      try {
        let response = await BlueprintService.getGroupBlueprints(groupId);
        this.groupBlueprints = response.data;
      } catch (err) {
        console.log("getGroupBlueprints error: ", err);
      }
    },

    async initBarChart() {
      let self = this;
      let dataType = "temp";

      let services = this.$store.state.userServices;
      let sr = HelperMethods.getGroupSampleRate(services);

      // Reseting all barChart values
      this.barChartPayload = [];
      this.barChartSeriesData = [];
      this.barChartDates = [];
      this.barChartDevices = [];

      // Set the data to plot the bar chart
      // let groupId = this.selectedGroupObj.id;

      // Get all deviceGroups from the group to populate the choose device combobox button of the bar chart
      // this.getGroupDeviceGroups(groupId);

      var deviceIds = [];

      this.groupDevices.forEach(function (value) {
        deviceIds.push(Number(value.id));
      });
      
      if (deviceIds.length >= 8) {
        this.rotate = 15;
      } else this.rotate = 0;

      try {
        let response = await DataService.getDataFromAllDevices(
          deviceIds,
          dataType
        );
        this.barChartPayload = response.data;

        this.barChartPayload.forEach(function (value) {
          value.name = HelperMethods.nameDevice(
            value["refDeviceId"],
            self.groupDevices
          );
        });

        for (let i = 0; i < this.barChartPayload.length; i++) {
          this.barChartDates.push(this.barChartPayload[i].createdAt);
          this.barChartDevices.push(
            this.barChartPayload[i].name);

          let startTime = moment(this.barChartPayload[i].createdAt);
          let endTime = moment();
          if (endTime.diff(startTime, "seconds") >= sr) {
            this.barChartPayload[i].online = false;
          } else this.barChartPayload[i].online = true;

          if (this.barChartPayload[i].online) {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataType}`],
              itemStyle: {
                color: new echarts.graphic.LinearGradient(10, 10, 10, 1, [
                  { offset: 0, color: "#83bff6" },
                  { offset: 0.5, color: "#188df0" },
                  { offset: 1, color: "#3e77b6" },
                ]),
              },
            });
          } else {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataType}`],
              itemStyle: {
                color: "#B0BEC5",
              },
            });
          }
        }
      } catch (err) {
        console.log("initBarGraph error: ", err);
      }
    },

    async updateBarGraph(dataType) {
      // Sets variable "measure" to hold the selected dataType measure (ºC, db's, %, etc) to be passed to the graph
      this.measure = HelperMethods.defineDataMeasure(dataType);

      // If the number of devices to be shown on the graph is superior to 8 then their names will be rotated to avoid overlapping
      // if (this.selectedDevices.length >= 8) {
      //   this.rotate = 15;
      // } else this.rotate = 0;
      
      let services = this.$store.state.userServices;
      let sr = HelperMethods.getGroupSampleRate(services);

      let self = this;
      let dataOfType = "";
      this.barChartPayload = [];
      this.barChartDates = [];
      this.barChartSeriesData = [];
      this.barChartDevices = [];
      dataOfType = HelperMethods.getDataType(dataType);

      var deviceIds = [];

      this.groupDevices.forEach(function (value) {
        deviceIds.push(Number(value.id));
      });

      try {
        let response = await DataService.getDataFromAllDevices(
          deviceIds,
          dataOfType
        );
        this.barChartPayload = response.data;

        this.barChartPayload.forEach(function (value) {
          value.name = HelperMethods.nameDevice(
            value["refDeviceId"],
            self.groupDevices
          );
        });

        for (let i = 0; i < this.barChartPayload.length; i++) {
          this.barChartDates.push(this.barChartPayload[i].createdAt);
          this.barChartDevices.push(
            this.barChartPayload[i].name);

          let startTime = moment(this.barChartPayload[i].createdAt);
          let endTime = moment();
          if (endTime.diff(startTime, "seconds") >= sr) {
            this.barChartPayload[i].online = false;
          } else this.barChartPayload[i].online = true;

          if (this.barChartPayload[i].online) {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: new echarts.graphic.LinearGradient(10, 10, 10, 1, [
                  { offset: 0, color: "#83bff6" },
                  { offset: 0.5, color: "#188df0" },
                  { offset: 1, color: "#3e77b6" },
                ]),
              },
            });
          } else {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: "#B0BEC5",
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getGroupDeviceGroups(groupId) {
      this.groupDeviceGroups = [];
      this.deviceGroups = [];
      this.deviceGroup = [];
      try {
        let response = await DeviceGroupService.getGroupDeviceGroups({params: { groupId }});
        this.groupDeviceGroups = response.data;
        for (let i = 0; i < response.data.length; i++) {
          this.deviceGroups.push(response.data[i].name);
        }
        // Assigning "deviceGroup" a predefined value so the v-select displays it since the component is mounted
        this.deviceGroup = this.deviceGroups[0];
      } catch (err) {
        console.log(err);
      }
    },

    changeView(selectedView) {
      if (selectedView == "Global") {
        this.view = true;
        this.selectedDeviceGroup();
        return;
      } else if (selectedView == "By SensorBlock") {
        this.view = false;
        return;
      } else return null;
    },

    blueprintByFloor() {
      let path = HelperMethods.getBlueprintPathByFloorName(
        this.groupBlueprints,
        this.floor
      );
      let blueprintId = HelperMethods.getBlueprintIdByFloorName(
        this.groupBlueprints,
        this.floor
      );
      this.path = path;
      this.blueprintId = blueprintId;

      // Building an array of objects that store the device id and status
      let devicesToHighlight = [];
      for(let i = 0; i < this.barChartPayload.length; i++) {
        let device = {};
        device.id = this.barChartPayload[i].refDeviceId;
        device.status = this.barChartPayload[i].online;
        devicesToHighlight.push(device);
      }

      // HIGHLIGHTING THE PROPER DEVICES
      // Ask the CanvaObj to highlight the devices if they belong to the refBlueprint active
      this.$refs.canvaObj.highlightDevicesById(
        devicesToHighlight,
        blueprintId
      );
    },

    // Method triggered by the ChooseDeviceGroup button. Upon selection a deviceGroup name is attributted to the variable deviceGroup.
    async selectedDeviceGroup() {
      let refDeviceGroup;
      this.barChartDates = [];
      this.barChartSeriesData = [];
      this.barChartDevices = [];
      this.selectedDevices = [];
      let self = this;

      // Loop through the global array of objects "groupDeviceGroups", which is set on "mounted" and holds all group deviceGroups objects
      // and if a match between the deviceGroup name and the groupDeviceGroups name is found return that deviceGroup id
      for (let i = 0; i < this.groupDeviceGroups.length; i++) {
        if (this.deviceGroup === this.groupDeviceGroups[i].name) {
          refDeviceGroup = this.groupDeviceGroups[i].id;
        }
      }

      try {
        // Query the database for all devices belonging to the group passed
        let response = await DeviceGroupService.getDevicesFromGroup({
          params: { refDeviceGroup },
        });

        // Push all group devices returned from the DB to the selectedDevices array
        for (let i = 0; i < response.data.length; i++) {
          self.selectedDevices.push(response.data[i].refDevice);
        }

        // HIGHLIGHTING THE PROPER DEVICES
        // Store on refBlueprint variable the ID of the current blueprint being displayed
        let refBlueprint = HelperMethods.getBlueprintIdByFloorName(
          this.groupBlueprints,
          this.floor
        );

        // If the group selected has no devices we stop the execution here
        if(response.data.length === 0) {
          console.log("group has no devices")
          return;
        }

        // UPDATING THE BAR GRAPH
        // If the number of devices to be shown on the graph is superior to 8 then their names will be rotated to avoid overlapping
        if (this.selectedDevices.length >= 8) {
          this.rotate = 15;
        } else this.rotate = 0;

        let services = this.$store.state.userServices;
        let sr = HelperMethods.getGroupSampleRate(services);

        // Parse the dataType name attributted to the global variable "dataType" to a name the DB understands
        let dataOfType = HelperMethods.getDataType(this.dataType);

        // Query the DB for the data of the dataOfType for all devices passed
        let response1 = await DataService.getDataFromAllDevices(
          self.selectedDevices,
          dataOfType
        );

        // Store the array of objects returned from the DB query on the data obj
        this.barChartPayload = response1.data;

        // Create a new key/value pair with key "name" on the object, and give it its device name according to its refDeviceId
        this.barChartPayload.forEach(function (value) {
          value.name = HelperMethods.nameDevice(
            value["refDeviceId"],
            self.groupDevices
          );
        });

        for (let i = 0; i < this.barChartPayload.length; i++) {
          this.barChartDates.push(this.barChartPayload[i].createdAt);
          this.barChartDevices.push(
            this.barChartPayload[i].name);
          
          let startTime = moment(this.barChartPayload[i].createdAt);
          let endTime = moment();
          if (endTime.diff(startTime, "seconds") >= sr) {
            this.barChartPayload[i].online = false;
          } else this.barChartPayload[i].online = true;

          if (this.barChartPayload[i].online) {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: new echarts.graphic.LinearGradient(10, 10, 10, 1, [
                  { offset: 0, color: "#83bff6" },
                  { offset: 0.5, color: "#188df0" },
                  { offset: 1, color: "#3e77b6" },
                ]),
              },
            });
          } else {
            this.barChartSeriesData.push({
              value: this.barChartPayload[i][`${dataOfType}`],
              itemStyle: {
                color: "#B0BEC5",
              },
            });
          }
        }

        // Building an array of objects that store the device id and status
        let devicesToHighlight = [];
        for(let i = 0; i < this.barChartPayload.length; i++) {
          let device = {};
          device.id = self.barChartPayload[i].refDeviceId;
          device.status = self.barChartPayload[i].online;
          devicesToHighlight.push(device);
        }

        // HIGHLIGHTING THE PROPER DEVICES
        // Ask the CanvaObj to highlight the devices if they belong to the refBlueprint active
        self.$refs.canvaObj.highlightDevicesById(
          devicesToHighlight,
          refBlueprint
        );
      } catch (err) {
        console.log(err);
      }
    },

  },

  async mounted() {

    // Upon mounting the component we populate the appGroupsName array by getting all the app's groups
    try {
      let response = await GroupService.getAllGroups();
      this.appGroupsObj = response.data;
    } catch (err) {
      console.log("err: ", err);
    }

    for (let i = 0; i < this.appGroupsObj.length; i++) {
      this.appGroupsNames.push(this.appGroupsObj[i].name);
    }

    // Setting the default initial value for selectedView so the v-select displays it after mount
    this.selectedView = "By SensorBlock";

    // Setting default dataType to pot the lineGraph as "Temperature"
    this.dataType = "Temperature";

    // Setting the default values for time period (daily)
    this.timePeriod = "Daily";
    this.dateFrom = moment().subtract(1, "days").format("YYYY-MM-DD h:mm:ss a");
    this.dateTo = moment().add(0, "days").format("YYYY-MM-DD h:mm:ss a");
  },

  computed: {
    temperature() {
      return this.payload?.data?.temp;
    },
    noise() {
      return this.payload?.data?.dbavg;
    },
    humidity() {
      return this.payload?.data?.humidity;
    },
    co2() {
      return this.payload?.data?.eco2;
    },
    lux() {
      return this.payload?.data?.lux;
    },
    wifi() {
      return this.payload?.data?.rssi;
    },
    tvoc() {
      return this.payload?.data?.tvoc;
    },
    pressure() {
      return this.payload?.data?.pressure;
    },
    lightTemp() {
      return this.payload?.data?.light_temp_raw;
    },
    r() {
      return this.payload?.data?.r;
    },
    g() {
      return this.payload?.data?.g;
    },
    b() {
      return this.payload?.data?.b;
    },
    devicesCount() {
      return this.payload?.data?.devicesCount;
    },
    devicesFlow() {
      return this.payload?.data?.devicesFlow;
    },
    time() {
      return moment(this.payload?.data?.createdAt).format(
        "Do MMMM YYYY h:mm:ss a"
      );
    },
  },
};
</script>

<style>
.mother {
  height: 96vh;
  width: 80vw;
  /* border: 1px solid yellowgreen; */
}

.button {
  margin-left: 10px;
  margin-top: 10px;
}

.topObj {
  height: 45vh;
  /* border: 1px solid red; */
}
.botObj {
  height: 45vh;
  /* border: 1px solid blue; */
}
.mainButtons {
  border-radius: 20px;
  /* border: 1px solid blueviolet; */
}
.dashboard {
  border-radius: 20px;
  /* border: 1px solid brown; */
}
.blueprint {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}
.lineGraphButtons {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}
.barGraphButtons {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}
.lineGraph {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}
.barGraph {
  border-radius: 20px;
  /* border: 1px solid burlywood; */
}
</style>
